import React, {useState} from 'react';
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import {
    FormControl,
    FormGroup, InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TableCell,
    TableHead,
    TextareaAutosize
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Card from "@mui/material/Card";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import MDButton from "../../components/MDButton";
import {useCreateTargetMutation} from "../../Redux/slices/targetApiSlice";
import {useSelector} from "react-redux";
import {errorToast, infoToast} from "../../utils/toastify";
import {DatePicker, DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {useCreatePostMutation, useUploadImagesMutation} from "../../Redux/slices/postApiSlice";
import dayjs from "dayjs";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {useCreatePaymentMutation, useGetPaymentQuery, useGetUSDQuery} from "../../Redux/slices/cryptoApiSlice";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";


const AddBalance = () => {
    const auth = useSelector(state => state.auth.userInfo.user)
    const [currency, setCurrency] = useState(0)
    const [dollar, setDollar] = useState(0)
    const [address, setAddress] = useState('')
    const {data: payment} = useGetPaymentQuery(address)
    const {data: course} = useGetUSDQuery()

    const [createPayment, {isLoading}] = useCreatePaymentMutation()
    const handleChange = (e) => {

    };
    const submitHandler = async () => {
        try {
            const res = await createPayment({user: auth.username, amount: currency})
            setAddress(res.data.address)
        } catch (e) {
            errorToast("Error")
        }

    }
    const onCopy = (item) => {
        navigator.clipboard.writeText(item);
    }
    const onCrypto = (e) => {
        const inputValue = e.target.value;

        // Проверяем, является ли значение пустым или положительным числом
        if (inputValue === '' || parseFloat(inputValue) >= 0) {
            setCurrency(inputValue);
            setDollar(e.target.value * course.data)

        }
    }

    return (

        <>
            <DashboardLayout>
                <DashboardNavbar/>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-8 col-md-10 col-12 m-auto">

                                <Card>
                                    <MDBox
                                        mx={2}
                                        mt={3}
                                        py={3}
                                        px={2}
                                        variant="gradient"
                                        bgColor="info"
                                        borderRadius="lg"
                                        coloredShadow="info"
                                    >
                                        <MDTypography variant="h6" color="white">
                                            {!address ? "Add Balance in BTC" : "Complete the payment"}
                                        </MDTypography>
                                    </MDBox>
                                    <div style={{padding: '5%'}} className="card-body">


                                        {!address ? (
                                            <FormGroup>



                                                <TextField
                                                    value={currency}
                                                    onChange={onCrypto}
                                                    type="number"
                                                    step={'any'}
                                                    variant='outlined'
                                                    color='secondary'
                                                    inputProps={{
                                                        "min": "0.01"
                                                    }}
                                                    label="Amount in BTC"
                                                    required
                                                    fullWidth
                                                    sx={{mb: 4}}
                                                />
                                                <MDTypography>{`You will get ${dollar.toFixed()}$`}</MDTypography>



                                                <FormControl>
                                                    <MDButton style={{marginTop: '5'}} variant="contained" color={'info'} onClick={submitHandler} type="button">{isLoading ? "Loading" : "Create Payment"}</MDButton>

                                                </FormControl>
                                            </FormGroup>
                                        ): (
                                            <>
                                                <TextField
                                                    value={address}
                                                    type="text"
                                                    disabled
                                                    variant='outlined'
                                                    color='secondary'
                                                    InputProps={{endAdornment: (
                                                            <InputAdornment><Tooltip title={"Copy"}><MDButton onClick={(() => onCopy(address))} color={'info'}><Icon>content_copy</Icon></MDButton></Tooltip></InputAdornment>
                                                        )}}
                                                    label="address"
                                                    required
                                                    fullWidth
                                                    sx={{mb: 4}}
                                                />
                                                <TextField
                                                    value={currency}
                                                    type="number"
                                                    disabled
                                                    variant='outlined'
                                                    color='secondary'
                                                    InputProps={{endAdornment: (
                                                            <InputAdornment><Tooltip title={"Copy"}><MDButton onClick={(() => onCopy(currency))} color={'info'}><Icon>content_copy</Icon></MDButton></Tooltip></InputAdornment>
                                                        )}}
                                                    label="Amount"
                                                    required
                                                    fullWidth
                                                    sx={{mb: 4}}
                                                />
                                                <MDTypography>{`You will get ${dollar.toFixed()}$`}</MDTypography>

                                            </>
                                        )}
                                    </div>
                                </Card>
                            </div>
                        </div>

                    </div>
                </LocalizationProvider>
            </DashboardLayout>
        </>

    );
};

export default AddBalance;