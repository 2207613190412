/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import partnersTableData from "./data/partnersTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import PartnerEdit from "./modal";
import {useEffect, useState} from "react";
import CreateClient from "../../components/Modals/CreateClient";
import CreatePartner from "../../components/Modals/CreatePartner";
import {
    useBlockPartnerMutation,
    useDeleteUserMutation,
    useGetClientsQuery,
    useGetOnlineQuery,
    useGetPartnersQuery
} from "../../Redux/slices/userApiSlice";
import clientTableData from "../client/data/clientTableData";
import {errorToast, infoToast} from "../../utils/toastify";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import UpdatePassword from "../../components/Modals/UpdatePassword";
import UpdatePasswordPartner from "../../components/Modals/UpdatePasswordPartner";
import MDBadge from "../../components/MDBadge";

function Partner() {
    const [open, setOpen] = useState(false)
    const {data: partners} = useGetPartnersQuery()
    const [deleteUser, {isLoading}] = useDeleteUserMutation()
    const { columns } = partnersTableData();
    const [rows, setRows] = useState([])
    const {data: onlines} = useGetOnlineQuery()
    const [blockPartner] = useBlockPartnerMutation()

    const onDelete = async (id) => {
        if (confirm("Are you sure?")){
            try {
                const res = await deleteUser(id)
                infoToast("Success")
            } catch (err){
                errorToast(err.error)
            }
        }
    }
    const onBlock = async (id) => {
        if (confirm("Are you sure?")){
            try {
                const res = await blockPartner(id)
                infoToast("Success")
            } catch (err){
                errorToast(err.error)
            }
        }
    }

    useEffect(() => {
        let arr = []
        if (partners && onlines){
            arr = partners.data.map(item => {
                    return  {
                        id: <MDTypography>{item.id}</MDTypography>,
                        username: (
                            <MDTypography>{item.username}</MDTypography>
                        ),
                        balance: (
                            <MDTypography>{item.balance}$</MDTypography>
                        ),
                        online: (
                            <MDBox ml={-1}>
                                <MDBadge badgeContent={onlines.data.includes(item.id) ? "online" : "offline"} color={onlines.data.includes(item.id) ? "success" : "error"} variant="gradient" size="sm" />
                            </MDBox>
                        ),
                        block: (
                            <MDButton  color={item.isBlocked ? "error" : "success"} onClick={() => onBlock(item.id)}>
                                <Icon>block</Icon>
                            </MDButton>
                        ),


                        date: (
                            <MDTypography></MDTypography>
                        ),
                        action: (
                            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                                <Tooltip title={'Delete'}>
                                    <MDButton color={'error'} onClick={() => onDelete(item.id)} style={{marginRight: 15}} variant={'contained'}><Icon>delete</Icon></MDButton>
                                </Tooltip>
                                <UpdatePasswordPartner userId={item.id}/>
                            </MDTypography>
                        ),


                    }
            })
        }
        setRows(arr)
    }, [partners, onlines])
    return (
        <DashboardLayout>
            <PartnerEdit open={open}/>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    <Grid container>
                                        <Grid item xs={10}>
                                            <MDTypography variant="h6" color="white">
                                                Partners
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <CreatePartner/>
                                        </Grid>
                                    </Grid>
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={false}
                                    entriesPerPage={false}
                                    showTotalEntries={false}
                                    noEndBorder
                                />
                            </MDBox>
                        </Card>
                    </Grid>

                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Partner;
