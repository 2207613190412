import React, { useEffect, useState } from "react";
import { Treebeard } from "react-treebeard";
import decorators from "react-treebeard/lib/components/decorators";
import defaultTheme from "react-treebeard/lib/themes/default";
import MDTypography from "../../components/MDTypography";
import style from "./TreeStyle";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import {FormControlLabel, Popover, Radio} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {setCart} from "../../Redux/slices/cartSlice";
import {useDispatch, useSelector} from "react-redux";
import MDButton from "../../components/MDButton";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "../../components/MDBox";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MDInput from "../../components/MDInput";
import {useNavigate} from "react-router-dom";

function getBaseName(filename) {
    return filename.substring(0, filename.lastIndexOf('.'));
}
function getFileExtension(filename) {
    const index = filename.lastIndexOf('.');
    return index !== -1 ? filename.substring(index + 1) : '';
}
const CustomHeader = ({ node, style }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const [checked, setChecked] = useState(false);
    const dispatch = useDispatch()
    const cart = useSelector(state => state.cart.cart)
    const handleChange = (event) => {
        if (!checked){
            dispatch(setCart({totalPrice: cart.totalPrice + node.price, files: [...cart.files, node]}))
        } else {
            const finalFiles = cart.files.filter(item => item.path !== node.path);
            dispatch(setCart({totalPrice: cart.totalPrice - node.price, files: finalFiles}))
        }
        setChecked(!checked);


    };
    const buyNow = () => {
            dispatch(setCart({totalPrice: cart.totalPrice + node.price, files: [...cart.files, node]}))
        navigate('/client/cart')

    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div style={style.base}>
            <div style={{ ...style.title, display: "flex", color: 'black' }}>
                    <Grid container>
                        <Grid item xs={10}>
                            {node.price ? (
                                <>
                                    <Grid container style={{width: '110%'}}>
                                        {/*<Grid item xs={1}>*/}
                                        {/*    <FormControlLabel control={<Checkbox style={{top: -5}}  value={checked} onChange={handleChange} />}/>*/}

                                        {/*        </Grid>*/}
                                        {/*<Grid item xs={1}>*/}
                                        {/*    <img style={{width: 25}} src={`${process.env.REACT_APP_BASE_URL}${localStorage.getItem('image')}`}/>*/}
                                        {/*</Grid>*/}
                                        <Grid item xs={3}>
                                            <Tooltip title={'Filename'}>
                                                <p>
                                                    {getBaseName(node.name)}

                                                </p>
                                            </Tooltip>

                                        </Grid>
                                        <Grid item xs={1}>
                                            <Tooltip title={'Extension of file'}>
                                                <p>
                                                    .{getFileExtension(node.name)}

                                                </p>
                                            </Tooltip>

                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title={'Date of last change'}>
                                                <p>
                                                    <Icon>access_time</Icon> {node.date}

                                                </p>
                                            </Tooltip>

                                        </Grid>
                                        <Grid item xs={2}>
                                            <Tooltip title={'Weight of file'}>
                                                <p>
                                                    <Icon>cloud_download</Icon> {node.weight} KB

                                                </p>
                                            </Tooltip>

                                        </Grid>
                                        <Grid item xs={1}>
                                            <Tooltip title={'Price'}>
                                                <p>
                                                    <Icon>sell</Icon> {node.price}$

                                                </p>
                                            </Tooltip>

                                        </Grid>
                                        <Grid item xs={1}>
                                            <Tooltip title={"Buy now"}>
                                                <MDButton onClick={buyNow} variant={'contained'} color={'info'}>
                                                    <Icon>flash_on</Icon>
                                                </MDButton>
                                            </Tooltip>

                                        </Grid>
                                        <Grid item xs={1}>

                                        <Tooltip title={'Add to cart'} onClick={handleChange}>
                                            <MDButton variant={'contained'} color={checked ? "error" : "success"}>
                                                <Icon>{checked ? "remove_shopping_cart" : "add_shopping_cart"}</Icon>
                                            </MDButton>
                                        </Tooltip>
                                        </Grid>

                                    </Grid>


                                </>
                            ) : <>
                                <>
                                    <Grid container>
                                        <Grid item xs={1}>
                                            <Icon>folder</Icon>
                                        </Grid>
                                        <Divider orientation={'vertical'} flexItem/>
                                        <Grid item xs={2}>
                                            {`${node.name} `}
                                        </Grid>
                                    </Grid>
                                </>
                            </> }
                        </Grid>

                    </Grid>
                <Divider/>
            </div>
        </div>
    );
};

// Кастомный контейнер для отображения узлов
const CustomContainer = ({ style, decorators, terminal, onClick, node }) => {
    const [checked, setChecked] = React.useState(true);
    const dispatch = useDispatch()
    const cart = useSelector(state => state.cart.cart)
    const handleChange = (event) => {
        setChecked(event.target.checked);
        if (event.target.checked){
            dispatch(setCart({totalPrice: cart.totalPrice + node.price, files: [...cart.files, node.path]}))
        } else {
            const finalFiles = cart.files.filter(item => item.path !== node.path);
            dispatch(setCart({totalPrice: cart.totalPrice - node.price, files: finalFiles}))
        }

    };
    return (
        <div onClick={onClick} style={style.container[0]}>
            <decorators.Header node={node} style={style.header} />
            <span >

            </span>
            <Divider/>
        </div>
    );
};

// Оборачиваем кастомные декораторы в функциональный компонент
const CustomDecorators = {
    Header: CustomHeader,
    Container: CustomContainer,
};

// Основной компонент
const TreeView = ({ dataa, post }) => {
    const [sort, setSort] = useState("name");


    const handleChange = (event, newValue) => {
        setSort(newValue);
    };
    const [currentPath, setCurrentPath] = useState([]); // Хранит текущее имя узла
    const [allPaths, setAllPaths] = useState([])
    const [treeData, setTreeData] = useState(dataa); // Изначальные данные дерева
    const [treeData2, setTreeData2] = useState(dataa); // Изначальные данные дерева

    const [fullData, setFullData] = useState(dataa); // Хранит полные данные дерева для возврата
    function getPaths(node, path = []) {
        // Добавляем текущий узел в путь
        const currentPath = [...path, node.name];

        // Если у узла есть дочерние элементы, рекурсивно продолжаем
        if (node.children && node.children.length > 0) {
            let paths = [];
            for (const child of node.children) {
                paths = paths.concat(getPaths(child, currentPath));
            }
            return paths;
        } else {
            // Если дочерних элементов нет, возвращаем текущий путь
            return [currentPath];
        }
    }

    const recursiveSort = (nodes) => {
        return nodes.map(node => {
            if (node.children) {
                // Рекурсивно сортируем дочерние узлы
                const sortedChildren = recursiveSort(node.children);
                return { ...node, children: sortedChildren };
            }
            return node;
        }).sort((a, b) => a.name.localeCompare(b.name)); // Сортируем по имени
    };

    const sortByCurrentPath = () => {
        const lastNodeName = currentPath[currentPath.length - 1];
        const parentNode = fullData.children.find(node => node.name === lastNodeName);

        if (parentNode && parentNode.children) {
            const sortedChildren = recursiveSort(parentNode.children);
            setTreeData(sortedChildren);
        }
    };


    // Обработчик для переключения состояния узлов
    function findPathToFolder(node, targetName, path = []) {
        // Добавляем текущее имя узла к пути
        const currentPath = [...path, node.name];

        // Проверяем, совпадает ли текущее имя узла с искомым
        if (node.name === targetName) {
            return currentPath; // Возвращаем путь, если нашли
        }

        // Если у узла есть дочерние элементы, продолжаем поиск
        if (node.children && node.children.length > 0) {
            for (const child of node.children) {
                const result = findPathToFolder(child, targetName, currentPath);
                if (result) {
                    return result; // Возвращаем результат, если нашли в дочерних
                }
            }
        }

        // Если не нашли, возвращаем null
        return null;
    }

// Применение функции
//     const targetName = 'Grandchild 1';
//     const pathToTarget = findPathToFolder(treeData, targetName);
//     console.log(pathToTarget);

    const handleToggle = (node) => {
        if (node.children && node.children.length > 0) {
            const targetName = node.name
            const pathToTarget = findPathToFolder(fullData, targetName);
            setCurrentPath(pathToTarget)
            setTreeData(node.children);
            setTreeData2(node.children);
        }
    };

    // Функция для возврата на уровень вверх
    const goBack = () => {
        // Устанавливаем новый путь, убирая последний элемент
        const prePath = currentPath.slice(0, -1); // Пропускаем первый элемент
        setCurrentPath(prePath)
        const newPath = currentPath.slice(0, -1); // Пропускаем первый элемент


        // Если новый путь пуст, возвращаемся к полному дереву
        if (newPath.length === 0) {
            setTreeData(fullData); // Возвращаем полное дерево
            setTreeData2(fullData); // используем только дочерние элементы

            return;
        }

        // Находим родительский узел
        let currentNode = fullData;

        // Проходим по всем элементам пути, чтобы найти узел
        for (const name of newPath) {
            const nextNode = currentNode.children.find(child => child.name === name);
            const index = newPath.indexOf(name)


            if (nextNode) {
                currentNode = nextNode; // Переходим к следующему узлу
            } else {
                if (index === 0){
                    continue;
                }
                console.error(`Parent node not found for: ${name}`);
                return; // Прекращаем выполнение, если узел не найден
            }
        }

        // Устанавливаем текущее состояние на дочерние элементы найденного узла
        setTreeData(currentNode.children || []); // Устанавливаем детей найденного узла или пустой массив
        setTreeData2(currentNode.children || []); // Устанавливаем детей найденного узла или пустой массив

    };



    const searchNodesInPath = (nodes, currentPath, query) => {
        // Если текущий путь пуст, возвращаем пустой массив
        if (currentPath.length === 0) return [];

        // Функция для рекурсивного поиска родительского узла
        const findParentNode = (nodes, name) => {
            for (const node of nodes) {
                if (node.name === name) {
                    return node;
                }

                // Рекурсивно ищем в дочерних узлах
                if (node.children) {
                    const found = findParentNode(node.children, name);
                    if (found) return found;
                }
            }
            return null; // Возвращаем null, если узел не найден
        };

        const lastNodeName = currentPath[currentPath.length - 1];
        const parentNode = findParentNode(nodes, lastNodeName);

        // Если родительский узел не найден, возвращаем пустой массив
        if (!parentNode) return [];

        // Функция для поиска по дочерним элементам родительского узла
        const results = [];

        const searchChildren = (children) => {
            children.forEach(node => {
                // Проверяем соответствие имени
                if (node.name.toLowerCase().includes(query.toLowerCase())) {
                    results.push(node);
                }

                // Рекурсивно ищем в дочерних узлах
                if (node.children) {
                    searchChildren(node.children);
                }
            });
        };

        // Начинаем поиск только From дочерних элементов найденного узла
        if (parentNode.children) {
            searchChildren(parentNode.children);
        }

        return results;
    };





    // Функция для перехода на родительский узел по клику
    const handleParentClick = (name) => {
        const newPath = [...currentPath.slice(0, currentPath.length - 1), name]; // Обновляем путь
        const parentNode = fullData.children.find(child => child.name === name); // Находим узел родителя

        if (parentNode && parentNode.children) {
            setTreeData(parentNode.children); // Устанавливаем дочерние узлы для отображения
            setTreeData2(parentNode.children); // Устанавливаем дочерние узлы для отображения


        }

        setCurrentPath(newPath); // Обновляем текущее состояние пути
    };
    const handleSearch = (event) => {
        const query = event.target.value.trim();

        if (query) {
            // Поиск только по дочерним элементам в пределах текущего пути
            const results = searchNodesInPath(fullData.children, currentPath, query);
            setTreeData(results.length > 0 ? results : []); // Устанавливаем найденные результаты или пустой массив
            setTreeData2(results.length > 0 ? results : []); // Устанавливаем найденные результаты или пустой массив
        } else {
            // Если строка пустая, необходимо вернуть дочерние узлы текущего узла в currentPath
            if (currentPath.length === 0) {
                // Если путь пуст, возвращаем корневые дочерние узлы
                setTreeData(fullData.children || []); // Вернуть дочерние элементы корня
                setTreeData2(fullData.children || []);
            } else {

                // Находим текущий узел по текущему пути

                let currentNode = fullData;
                const nextNode = currentNode.children.find(child => child.name === name);
                const index = currentPath.indexOf(name)
                for (const name of currentPath) {
                    const nextNode = currentNode.children.find(child => child.name === name);
                    if (nextNode) {
                        currentNode = nextNode; // Перейдем к следующему узлу в пути
                    } else {
                        if (index === 0){
                            continue;
                        }
                    }
                }

                // Устанавливаем дочерние элементы текущего узла в treeData
                setTreeData(currentNode.children || []); // Устанавливаем детей текущего узла или пустой массив
                setTreeData2(currentNode.children || []);
            }
        }
    };


    const [sortKey, setSortKey] = useState('name');

    const sortData = (data) => {
        return data.sort((a, b) => {
            if (sort === 'price') {
                return a.price - b.price;
            }
            if (sort === 'weight') {
                // Сортировка по дате (от раннего к позднему)
                return new Date(a.date) - new Date(b.date);
            }
            if (sort === 'extension') {
                // Сортировка по имени (в алфавитном порядке)
                return a.name.localeCompare(b.name);

            }
            // Сортировка по весу (от большего к меньшему)
            if (sort === 'date') {
                return b.weight - a.weight;
            }

            if (sort === 'name') {
                const aExt = getFileExtension(a.name)
                const bExt = getFileExtension(b.name)

                return aExt.localeCompare(bExt); // Сравнение расширений
            }
            return 0; // Если ничего не соответствует
        });
    };

    const handleSortChange = (event, newValue) => {
        setSort(newValue);
        const sortedData = sortData([...treeData]); // Клонируем и сортируем данные
        setTreeData(sortedData); // Обновляем состояние с отсортированными данными
    };


    return (
        <>
            <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                color={'white'}

            >
                <MDBox bgColor={'white'} color={'black'}
                       borderRadius="lg"
                       p={3}
                >
                    <div className="path-container">
                        {currentPath.join(' > ')}
                        {currentPath.length > 1 && (
                            <MDButton variant={'contained'} style={{marginLeft: 10}} color={'info'} onClick={goBack}><Icon>arrow_back</Icon></MDButton> // Кнопка для возврата на уровень выше
                        )}
                    </div>

                    <MDBox mx={2} mt={3} py={3} px={2}>

                        <Grid container>
                            <Grid item xs={4}>
                                <MDInput
                                    type="text"
                                    placeholder="Search..."
                                    onChange={handleSearch}
                                    style={{ width: '100%', padding: '10px', borderRadius: '4px' }}
                                />
                            </Grid>

                            <Grid item xs={8}>

                                <Tabs value={sort} onChange={handleSortChange} aria-label="basic tabs example">
                                    <Tab label="Sort by Name" value={'name'} />
                                    <Tab label="Sort by Extension" value={'extension'} />
                                    <Tab label="Sort by Weight" value={'weight'}/>
                                    <Tab label="Sort by Date" value={'date'}/>
                                </Tabs>
                            </Grid>

                        </Grid>
                    </MDBox>
                </MDBox>
            </MDBox>

            <MDBox
                mx={2}
                mt={3}
                py={3}
                px={2}
            >
                <Treebeard
                    style={style}
                    data={treeData} // Для обновления данных дерева
                    decorators={CustomDecorators}
                    onToggle={handleToggle} // Устанавливаем обработчик
                />
            </MDBox>
        </>
    );
};


export default TreeView;

